<template>
  <div class="home" ref="home" id="home">
    <!-- section1 -->
    <div class="home_section_top">
      <div class="home_section_top_container space-between-row">
        <div class="col">
          <div class="home_section_top_title fc1">{{ this.$t("home.section1.title") }}</div>
          <div class="home_section_top_subtitle fc2">{{ this.$t("home.section1.subTitle") }}</div>
          <el-popover
            :popper-class="'qrcode-popover shadow-'+theme"
            placement="bottom-start"
            width="248"
            :visible-arrow="false"
            trigger="click">
            <div slot="reference" class="home_section_top_btn pointer">
              {{ this.$t("home.section1.contactUs") }}
            </div>
            <img class="code-item-img" :src="require(`@/assets/img/work-weixin-${theme}.png`)" width="248" height="248"/>
          </el-popover>
        </div>
        <div class="col">
          <img class="home_section_top_img" :src="require(`@/assets/img/banner.png`)"/>
        </div>
      </div>
    </div>

    <!-- section3 -->
    <div class="home_section bc1">
      <div class="home_section_container space-between-row">
        <div>
          <img class="home_section_img" :src="require(`@/assets/img/prod2.png`)"/>
        </div>
        <div class="home_section_text">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section3.title") }}</label>
            <img class="jump-img" :src="require(`@/assets/img/icon-right-${theme}.png`)" @click="router('')"/>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_sub hover-jump lang col">
              <label class="home_section_title2 fc1">{{ this.$t("home.section3.info1.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section3.info1.subTitle") }}</label>
            </div>
            <div class="home_section_sub hover-jump lang col">
              <label class="home_section_title2 fc1">{{ this.$t("home.section3.info2.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section3.info2.subTitle") }}</label>
            </div>
            <div class="home_section_sub hover-jump lang col">
              <label class="home_section_title2 fc1">{{ this.$t("home.section3.info3.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section3.info3.subTitle") }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section2 -->
    <div class="home_section home_section_gotrace bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section2.title") }}</label>
            <img class="jump-img" :src="require(`@/assets/img/icon-right-${theme}.png`)" @click="router('')"/>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_sub hover-jump col" :class="lang=='en'?'w100':''">
              <label class="home_section_title2 fc1">{{ this.$t("home.section2.info1.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section2.info1.subTitle") }}</label>
            </div>
            <div class="home_section_sub hover-jump col" :class="lang=='en'?'w100':''">
              <label class="home_section_title2 fc1">{{ this.$t("home.section2.info2.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section2.info2.subTitle") }}</label>
            </div>
            <div class="home_section_sub hover-jump col" :class="lang=='en'?'w100':''">
              <label class="home_section_title2 fc1">{{ this.$t("home.section2.info3.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section2.info3.subTitle") }}</label>
            </div>
            <div class="home_section_sub hover-jump col" :class="lang=='en'?'w100':''">
              <label class="home_section_title2 fc1">{{ this.$t("home.section2.info4.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section2.info4.subTitle") }}</label>
            </div>
            <div class="home_section_btn_trace space-between-row hover-jump pointer" @click="router('')">
              <div class="home_section_btn_trace_title1 fc1">{{ this.$t("home.section2.btn.title") }}</div>
              <div class="home_section_btn_trace_title2 fc2">{{ this.$t("home.section2.btn.subTitle") }}</div>
            </div>
          </div>
        </div>
        <div>
          <img class="home_section_img" :src="require(`@/assets/img/prod1.png`)"/>
        </div>
      </div>
    </div>
    <!-- section4 -->
    <div class="home_section bc1">
      <div class="home_section_container space-between-row">
        <div>
          <img class="home_section_img" :src="require(`@/assets/img/prod3.png`)"/>
        </div>
        <div class="home_section_text">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section4.title") }}</label>
            <img class="jump-img" :src="require(`@/assets/img/icon-right-${theme}.png`)" @click="router('')"/>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_sub hover-jump lang col">
              <label class="home_section_title2 fc1">{{ this.$t("home.section4.info1.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section4.info1.subTitle") }}</label>
            </div>
            <div class="home_section_sub hover-jump lang col">
              <label class="home_section_title2 fc1">{{ this.$t("home.section4.info2.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section4.info2.subTitle") }}</label>
            </div>
            <div class="home_section_sub hover-jump lang col">
              <label class="home_section_title2 fc1">{{ this.$t("home.section4.info3.title") }}</label>
              <label class="home_section_title3 fc2">{{ this.$t("home.section4.info3.subTitle") }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section5 team-->
    <div class="home_section home_section_service bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section5.title") }}</label>
            <img class="jump-img" :src="require(`@/assets/img/icon-right-${theme}.png`)" @click="router('')"/>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_sub hover-jump col">
              <img :src="require(`@/assets/img/icon-team-1.png`)" width="96" height="96"/>
              <label class="home_section_title2 fc1">{{ this.$t("home.section5.info1.title") }}</label>
            </div>
            <div class="home_section_sub hover-jump col">
              <img :src="require(`@/assets/img/icon-team-2.png`)" width="96" height="96"/>
              <label class="home_section_title2 fc1">{{ this.$t("home.section5.info2.title") }}</label>
            </div>
            <div class="home_section_sub hover-jump col">
              <img :src="require(`@/assets/img/icon-team-3.png`)" width="96" height="96"/>
              <label class="home_section_title2 fc1">{{ this.$t("home.section5.info3.title") }}</label>
            </div>
            <div class="home_section_sub hover-jump col">
              <img :src="require(`@/assets/img/icon-team-4.png`)" width="96" height="96"/>
              <label class="home_section_title2 fc1">{{ this.$t("home.section5.info4.title") }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section6 成果-->
    <div class="home_section home_section_achievements bc1">
      <div class="home_section_container space-between-row">
        <div class="col achievements-item">
          <div>
            <label class="title">{{ this.$t("home.section6.info1.title") }}</label>
            <label class="unit">{{ this.$t("home.section6.info1.unit") }}</label>
          </div>
          <div>
            <label class="subTitle">{{ this.$t("home.section6.info1.subTitle") }}</label>
          </div>
        </div>
        <div class="col achievements-item">
          <div>
            <label class="title">{{ this.$t("home.section6.info2.title") }}</label>
            <label class="unit">{{ this.$t("home.section6.info2.unit") }}</label>
          </div>
          <div>
            <label class="subTitle">{{ this.$t("home.section6.info2.subTitle") }}</label>
          </div>
        </div>
        <div class="col achievements-item">
          <div>
            <label class="title">{{ this.$t("home.section6.info3.title") }}</label>
            <label class="unit">{{ this.$t("home.section6.info3.unit") }}</label>
          </div>
          <div>
            <label class="subTitle">{{ this.$t("home.section6.info3.subTitle") }}</label>
          </div>
        </div>
        <div class="col achievements-item">
          <div>
            <label class="title">{{ this.$t("home.section6.info4.title") }}</label>
            <label class="unit">{{ this.$t("home.section6.info4.unit") }}</label>
          </div>
          <div>
            <label class="subTitle">{{ this.$t("home.section6.info4.subTitle") }}</label>
          </div>
        </div>
      </div>
    </div>

    <!-- section7 case-->
    <div class="home_section home_section_case bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section7.title") }}</label>
            <img class="jump-img" :src="require(`@/assets/img/icon-right-${theme}.png`)" @click="router('')"/>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_sub hover-jump col" v-for="item in newsList" :key="item.url" @click="goBlank(item.url)">
              <div class="news_title fc1">{{ item.title }}</div>
              <div class="news_intro fc2">{{ item.detail }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section8 social-->
    <div class="home_section home_section_social bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section8.title") }}</label>
          </div>
          <div class="home_section_title2 space-between-row fc2" style="margin-top: 16px;">
            <label>{{ this.$t("home.section8.subTitle") }}</label>
          </div>
          <div class="row">
            <img class="hover-jump" @click="goBlank(x_url)" :src="require(`@/assets/img/icon-x.png`)" width="48" height="48"/>
            <img class="hover-jump" @click="goBlank(redbook_url)" :src="require(`@/assets/img/icon-redbook.png`)" width="48" height="48"/>
            <img class="hover-jump" @click="goBlank(tiktok_url)" :src="require(`@/assets/img/icon-tiktok.png`)" width="48" height="48"/>
            <el-popover
              :popper-class="'qrcode-popover shadow-'+theme"
              placement="bottom-start"
              width="240"
              :visible-arrow="false"
              trigger="click">
              <img slot="reference" class="hover-jump" :src="require(`@/assets/img/icon-wechat.png`)" width="48" height="48"/>
              <img class="code-item-img" :src="require(`@/assets/img/qrcode-weixin-${theme}.png`)" width="248" height="248"/>
            </el-popover>
          </div>
        </div>
        <div>
          <img :src="require(`@/assets/img/icon-news.png`)" width="380" height="230"/>
        </div>
      </div>
    </div>

    <!-- section9 us-->
    <div class="home_section home_section_us bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text">
          <div class="row">
            <div class="col">
              <label class="title fc1">
                {{ this.$t("home.section9.prod.title") }}
              </label>
              <label class="sub fc2">
                {{ this.$t("home.section9.prod.sub1") }}
              </label>
              <label class="sub fc2">
                {{ this.$t("home.section9.prod.sub2") }}
              </label>
              <label class="sub fc2">
                {{ this.$t("home.section9.prod.sub3") }}
              </label>
            </div>

            <div class="col justify-content-right">
              <label class="title fc1">
                {{ this.$t("home.section9.case.title") }}
              </label>
              <label class="sub fc2">
                {{ this.$t("home.section9.case.sub1") }}
              </label>
            </div>

            <div class="col justify-content-right">
              <label class="title fc1">
                {{ this.$t("home.section9.us.title") }}
              </label>
              <label class="sub fc2">
                {{ this.$t("home.section9.us.sub1") }}
              </label>
            </div>
          </div>
        </div>
        <div class="logo-intro col">
          <img :src="require(`@/assets/img/logo-${theme}.png`)" width="160" height="46"/>
          <div class="fc2"> {{ this.$t("home.section9.logo") }} </div>
        </div>
      </div>
    </div>

    <!-- section10 us-->
    <div class="home_section home_section_version">
      <div class="home_section_container space-between-row fc2">
        <div>{{ this.$t("home.section10.version") }}</div>
        <div class="row">
          <img :src="require(`@/assets/img/icon-bottom-x.png`)" width="24" height="24"/>
          <img :src="require(`@/assets/img/icon-bottom-redbook.png`)" width="24" height="24"/>
          <img :src="require(`@/assets/img/icon-bottom-tiktok.png`)" width="24" height="24"/>
          <img :src="require(`@/assets/img/icon-bottom-wechat.png`)" width="24" height="24"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsList } from "@/api/case";

export default {
  name: "home",
  mounted() {
    this.getArtList();
  },
  computed:{
    theme: function () {
      let t = this.$store.state.theme
      if(t==null){
        t = localStorage.getItem("theme")
      }
      if(t == null) t = 'dark'
      return t
    },
    lang: function () {
      return this.$i18n.locale
    }
  },
  data() {
    return{
      newsList: [],
      showRQCode1: false,
      showRQCode2: false,
      x_url: "https://x.com/BitJungle_team",
      tiktok_url: "https://www.douyin.com/user/MS4wLjABAAAAjmzdoKmLOD_yxzwFID-IiIHnjI3Nmm5M2bDuzIAkhj8cBl1mPmStgSPmW9fHpnva",
      redbook_url: "https://www.xiaohongshu.com/user/profile/6698756b000000000f03469e"
    }
  },
  methods: {
    showRQCode(index){
      if(index==1)
        this.showRQCode1 = !this.showRQCode1
      else(index==2)
        this.showRQCode2 = !this.showRQCode2

    },
    goBlank(url){
      window.open(url)
    },
    showMsg(msg){
      this.$message({
        showClose: false,
        customClass: 'bit-msg bit-msg-'+this.theme,
        message: msg
      });
    },
    router(path){
      if(!path || path==''){
        this.showMsg(this.$t("undoMsg"))
        return
      }
      this.$router.push(path)
    },
    getArtList(){
      newsList().then(res => {
        this.newsList = res.data.data.list
      })
    },
    contactUs() {
      // var container = this.$refs.home;
      // container.scrollTop = container.scrollHeight;
      this.$emit("scrollToBottom");
    },
    caseApply(){
      // let isLogin = localStorage.getItem("isLogin")?Number(localStorage.getItem("isLogin")):0
      // if(isLogin == 0){
      //   this.$emit("showLoginDia")
      //   return
      // }
      this.$store.state.headerTab = 1
      this.$router.push("caseApply")
      this.$emit("scrollToTop")
    },
    goTrack() {
      // let isLogin = localStorage.getItem("isLogin")?Number(localStorage.getItem("isLogin")):0
      // if(isLogin == 0){
      //   this.$emit("showLoginDia")
      //   return
      // }
      this.$router.push("relationChart");
    },
  },
};
</script>
<style lang="scss" scoped>
.dark{
  .home_section_top{
    background-image: url("../../assets/img/banner-dark.png");
  }
  .home_section_btn_trace {
    background-image: url("../../assets/img/banner-bottom-dark.png");
  }
  .home_section_social {
    background-image: url("../../assets/img/banner-news-dark.png");
  }
  .code-item{
    background-image: url("../../assets/img/banner-qrcode-dark.png");
  }
}
.light{
  .home_section_top{
    background-image: url("../../assets/img/banner-light.png");
  }
  .home_section_btn_trace {
    background-image: url("../../assets/img/banner-bottom-light.png");
  }
  .home_section_social {
    background-image: url("../../assets/img/banner-news-light.png");
  }
  .code-item{
    background-image: url("../../assets/img/banner-qrcode-light.png");
  }
}
.jump-img{
  display: none;
}
.code-item{
  position: absolute;
  width: 248px;
  height: 248px;
  padding: 40px;
  box-sizing: border-box;
  z-index: 10;
  border-radius: 1px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  img{
    width: 168px;
    height: 168px;
  }
}
.home {
  box-sizing: border-box;
  flex: 1;
  .home_section_top {
    height: 620px;
    display: flex;
    justify-content: center;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    .home_section_top_container {
      width: 1200px;
      height: 620px;
      padding: 105px 0;
      position: relative;
      box-sizing: border-box;
      .col{
        justify-content: center;
      }
    }
    .home_section_top_title {
      height: 64px;
      line-height: 64px;
      font-size: 56px;
      font-weight: 500;
    }

    .home_section_top_btn {
      position: relative;
      margin-top: 80px;
      width: 144px;
      height: 48px;
      line-height: 48px;
      box-sizing: border-box;
      background: linear-gradient(109deg, #1C5BF9 1%, #1C5BF9 11%, #13B9CB 48%, #32E355 85%);
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      .code-item{
        position: absolute;
        top: 60px;
      }
    }

    .home_section_top_subtitle {
      margin-top: 16px;
      font-size: 24px;
    }

    .home_section_top_describe {
      font-size: 23px;
      margin-top: 5px;
      width: 530px;
    }

    .home_section_top_img{
      width: 480px;
      height: 410px;
      position: absolute;
      right: 0;
    }
  }

  .home_section {
    height: 608px;
    display: flex;
    justify-content: center;
    .home_section_container {
      padding: 88px 40px;
      width: 1200px;
      height: 100%;
      box-sizing: border-box;
      align-items: center;
      .home_section_text{
        display: flex;
        flex-direction: column;
        width: 560px;
        .home_section_title1{
          height: 48px;
          opacity: 1;
          display: flex;
          align-items: center;
          font-size: 36px;
          font-weight: 500;
          letter-spacing: 0px;
          img{
            width: 24px;
            height: 24px;
          }
        }
        .home_section_title2{
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          height: 28px;
        }
        .home_section_title3{
          font-size: 16px;
          font-weight: normal;
          line-height: 24px;
          height: 24px;
        }
        .home_section_sub_list{
          margin-top: 40px;
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          .home_section_sub{
            width: 272px;
            height: 104px;
            padding-left: 32px;
            gap: 4px;
            justify-content: center;
            border-radius: 4px;
            box-sizing: border-box;
          }
          .lang{
            width: 100% !important;
          }
        }
        .home_section_btn_trace{
          width: 560px;
          height: 104px;
          background-position: 50%;
          background-size: cover;
          background-repeat: no-repeat;
          padding: 0px 32px;
          align-items: center;
          .home_section_btn_trace_title1{
            font-size: 20px;
            font-weight: 400;
          }
          .home_section_btn_trace_title2{
            font-size: 16px;
            font-weight: normal;
          }
        }
      }
      .home_section_img{
        width: 480px;
        height: 340px;
      }
    }
  }

  .home_section_gotrace{
    height: auto !important;
    min-height: 608px !important;
  }
  .home_section_service {
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        gap: 24px !important;
        .home_section_sub{
          width: 588px !important;
          height: 184px !important;
          align-items: center;
          img{
            width: 96px;
            height: 96px;
          }
        }
      }
    }
  }

  .home_section_achievements{
    height: 208px;
    background: linear-gradient(96deg, #4C1FD3 2%, #2E62E2 26%, #31C79D 65%, #54EA72 97%);
    color: #FFFFFF;
    .achievements-item{
      gap: 4px;
    }
    .title{
      font-size: 64px;
      height: 64px;
      line-height: 64px;
      font-weight: 600;
      margin-right: 2px;
    }
    .unit{
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 600;
    }
    .subTitle{
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      height: 24px;
    }
  }

  .home_section_case {
    height: 484px !important;
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        flex-wrap: wrap;
        width: 100%;
        gap: 24px !important;
        .home_section_sub{
          width: 384px !important;
          height: 220px !important;
          padding: 32px !important;
          .news_title{
            max-height: 64px;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            text-align: justify;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 限制为两行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
          .news_intro{
            font-size: 16px;
            font-weight: normal;
            line-height: 28px;
            max-height: 84px;
            text-align: justify;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* 限制为两行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }
      }
    }
  }

  .home_section_social{
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 390px;
    .home_section_container{
      padding: 88px 0px;
    }
    .home_section_text{
      height: 230px;
      .home_section_title2{
        margin-top: 16px;
        font-weight: 400 !important;
      }
      .row{
        margin-top: 60px;
        gap: 24px;
        position: relative;
        img{
          cursor: pointer;
        }
        .code-item{
          position: absolute;
          right: 100px;
          top: 60px;
        }
      }
    }
  }

  .home_section_us{
    height: 344px;
    .home_section_container{
      padding: 60px 0px;
      .home_section_text{
        width: auto;
        .row{
          gap: 80px;
          .col{
            width: 132px;
            height: 160px;
            gap: 16px;
            .title{
              font-size: 24px;
              font-weight: 400;
              line-height: 40px;
              height: 40px;
            }
            .sub{
              font-size: 16px;
              font-weight: normal;
              line-height: 24px;
              height: 24px;
            }
          }
          .col:nth-child(1){
            width: 253px;
          }
        }
      }
    }
    .logo-intro{
      gap: 16px;
      height: 160px;
      .fc2{
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        height: 24px;
      }
    }
  }

  .home_section_version{
    height: 64px;
    .home_section_container{
      height: 64px;
      padding: 20px 0px;
      div{
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        height: 24px;

        gap: 24px;
      }
    }
  }
}
</style>
